<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Fast Invoice
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Welcome"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="5"
        class="d-flex align-items-center  auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto "
        >
          <b-card-title
            title-tag="h1"
            class="font-weight-bolder mb-1"
            style="font-size=3rem;"
          >
            Welcome!
          </b-card-title>
          <b-card-text
            style="margin-bottom: 0;"
            class="font-weight-bolder font-weight-bold"
          >
            Get up and running in just 3 minutes.
          </b-card-text>
          <b-card-text class="font-weight-bold">
            In a few steps, we’ll ask you to:
          </b-card-text>
          <div class="mt-2">
            <b-card-text class="font-weight-bold text-muted">
              <feather-icon
                style="color:7367f0;"
                icon="CheckIcon"
                size="14"
              />
              Add important details to set up your account
            </b-card-text>
            <b-card-text class="font-weight-bold text-muted">
              <feather-icon
                style="color:7367f0;"
                icon="CheckIcon"
                size="14"
              />
              Pick some options to make the most of your trial
            </b-card-text>
            <b-card-text class="font-weight-bold text-muted">
              <feather-icon
                style="color:7367f0;"
                icon="CheckIcon"
                size="14"
              />
              Explore how you can customize your invoice design
            </b-card-text>
          </div>
          <b-button
            class="mt-3"
            size="lg"
            variant="primary"
            :to="{name:'setup-business-details'}"
          >
            Get started
          </b-button>
          <!-- form -->

        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BLink, BImg, BButton,
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BButton,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BImg,
    BLink,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/register-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
